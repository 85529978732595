import React, { Component } from 'react'
import Top from '../common/top'
import Footer from '../common/footer'
import Sider from '../components/helpSider'

class Help extends Component {
  render() {
    return (
      <div ref={node => this.node = node}>
        {/*<Top />*/}
        <Sider />
        {/*<Footer />*/}
      </div>
    )
  }
  componentDidMount() {
    this.node.scrollIntoView();
  }
}

export default Help
