import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Homepage from '../pages/Homepage'
import Help from '../pages/Help'
import FogCDN from '../pages/FogCDN'
import Abput from '../pages/About'
import HomeLayout from '../layout/HomeLayout'
import PearStation from '../pages/PearStation'
/*const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" component={Homepage} exact></Route>
      <Route path="/api" component={Help} exact></Route>
      <Route path="/fogCDN" component={FogCDN} exact></Route>
      <Route path="/about" component={Abput} exact></Route>
    </Switch>
  </BrowserRouter>
)*/

const Routes = () => (
  <BrowserRouter>
    <Switch>
        <Route path="/fogCDN" component={FogCDN} exact></Route>
        <Route path="/api" component={Help} exact></Route>
      <Route path="/" component={HomeLayout}></Route>
    </Switch>
  </BrowserRouter>
)

const HomeRoutes = () => (
  <Switch>
    <Route path="/" component={Homepage} exact></Route>
    <Route path="/api" component={Help} exact></Route>
    <Route path="/fogCDN" component={FogCDN} exact></Route>
    <Route path="/about" component={Abput} exact></Route>
    <Route path="/goods/pear-station" component={PearStation} exact></Route>
  </Switch>
)

export { Routes, HomeRoutes }
