import React, { Component } from 'react'
// import { Layout } from 'antd'
// import CDNSider from './sider'
import './index.css'
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import HelpContent from '../helpContent';
import Top from '../../common/top';

// const { Sider, Content } = Layout
const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
    toolbarDrawer: theme.mixins.toolbar,
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
        backgroundColor: "#222",
        color: "#a68540",
        height: '73px',
    },
    toolBarColor: {
        backgroundColor: "#222",
        color: "#a68540",
        height: '73px',
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    menuButtonHidden: {
        display: 'none',
    },
    drawerItem: {
        // paddingLeft: theme.spacing(3),
    },
    drawerItem2: {
        paddingLeft: theme.spacing(5),
    },
    drawerItem3: {
        paddingLeft: theme.spacing(8),
    },
    drawerItem4: {
        paddingLeft: theme.spacing(11),
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        // position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    // content: {
    //     flexGrow: 1,
    //     padding: theme.spacing(3),
    //     height: '100vh',
    //     overflow: 'auto',
    // },
    chartContainer: {
        marginLeft: -22,
    },
    tableContainer: {
        height: 320,
    },
    h5: {
        marginBottom: theme.spacing(2),
    },
    a: {
        textDecoration:'none',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
});
const apis = [
    {
        name: '/v2/customer/nodes',
        desc: '获取HTTPS在线节点',
        method: 'get',
        params: [
            {
                name: 'client_ip',
                isMust: false,
                type: 'string',
                desc: '当没有该参数，或者client_ip的值为“0.0.0.0”或“127.0.0.1”时，服务器会自动获取该请求的公网IP地址'
            },
            {
                name: 'scheme',
                isMust: false,
                type: 'string',
                desc: 'http或https，如果没有则默认https'
            },
            {
                name: 'host',
                isMust: true,
                type: 'string',
                desc: '如请求资源的url为www.abc.com/tv/test.mp4，则host为www.abc.com'
            },
            {
                name: 'uri',
                isMust: true,
                type: 'string',
                desc: '如请求资源的url为www.abc.com/tv/test.mp4，则uri为/tv/test.mp4'
            },
        ],
        response: [
            {
                name: 'etag',
                type: 'string',
                desc: '文件时间戳和大小的签名',
            },
            {
                name: 'size',
                type: 'int',
                desc: '文件大小',
            },
            {
                name: 'piece_num',
                type: 'int',
                desc: '分片大小',
            },
            {
                name: 'piece_type',
                type: 'string',
                desc: '分片分发的类型（顺序，交叉，随机的模式）：Sequencial、Interleaved、Random',
            },
            {
                name: 'pieces',
                type: 'array',
                desc: '分片详细信息',
            },
            {
                name: 'uri',
                type: 'string',
                desc: '分片uri',
            },
            {
                name: 'start',
                type: 'int',
                desc: '分片开始位置',
            },
            {
                name: 'size',
                type: 'int',
                desc: '取模分片大小（256K，512K，1M）',
            },
            {
                name: 'nodes',
                type: 'array',
                desc: '节点详细信息',
            },
            {
                name: 'protocol',
                type: 'string',
                desc: '协议（HTTP/HTTPS）',
            },
            {
                name: 'host',
                type: 'string',
                desc: '节点地址',
            },
            {
                name: 'type',
                type: 'string',
                desc: '节点类型',
            },
            {
                name: 'magnet_uri',
                type: 'string',
                desc: '种子地址',
            },
            {
                name: 'capacity',
                type: 'int',
                desc: '能力值',
            },
        ],
        body:
            `{
  "etag": "string",
  "size": 0,
  "piece_num": 0,
  "piece_type": "string",
  "pieces": [
    {
      "uri": "string",
      "start": 0,
      "size": 0,
      "nodes": [
        {
          "protocol": "string",
          "host": "string",
          "type": "string",
          "magnet_uri": "string",
          "capacity": 0
        }
      ]
    }
  ]
}`
    },
    {
        name: '/v1/oss/cp/{user_id}/hosts',
        desc: '获取域名列表',
        method: 'get',
        response: [
            {
                name: 'id',
                type: 'int',
                desc: '域名的ID值'
            },
            {
                name: 'host',
                type: 'string',
                desc: '域名'
            },
            {
                name: 'user_name',
                type: 'Strng',
                desc: '用户名'
            },
            {
                name: 'state',
                type: 'string',
                desc: '域名受审核的状态'
            },
        ],
        body:
            `[
  {
    "id": 1,
    "host": "pear.hk",
    "user_name": "pear",
    "state": "authing"
  }
]`
    },
    {
        name: '/v1/oss/cp/{user_id}/cache/configs',
        desc: '获取缓存配置信息',
        method: 'get',
        response: [
            {
                name: 'cache_type',
                type: 'string',
                desc: '缓存文件的类型（文件，目录） file、dir'
            },
            {
                name: 'scheme',
                type: 'string',
                desc: '协议类型（http，https）'
            },
            {
                name: 'host',
                type: 'string',
                desc: '域名'
            },
            {
                name: 'uri',
                type: 'string',
                desc: '分发文件的路径'
            },
            {
                name: 'status',
                type: 'string',
                desc: '分发文件的状态'
            },
            {
                name: 'publish_time',
                type: 'int',
                desc: '分发文件的时间'
            },
            {
                name: 'finish_time',
                type: 'int',
                desc: '文件完成分发的时间'
            },
        ],
        body:
            `[
  {
    "cache_type": "file",
    "scheme": "https",
    "host": "pear.hk",
    "uri": "video/example.mp4",
    "status": "processing",
    "publish_time": 1508120211,
    "finish_time": 1508120211
  },
]`
    },
    {
        name: '/v1/oss/cp/{user_id}/traffic',
        desc: '流量统计',
        method: 'get',
        params: [
            {
                name: 'host',
                isMust: true,
                type: 'string',
                desc: '要查询产生流量的域名'
            },
            {
                name: 'start_date',
                isMust: true,
                type: 'int',
                desc: '统计流量的起始日期的时间戳'
            },
            {
                name: 'end_date',
                isMust: true,
                type: 'int',
                desc: '统计流量的结束日期的时间戳'
            },
        ],
        response: [
            {
                name: 'host',
                type: 'string',
                desc: '域名'
            },
            {
                name: 'mac_addr',
                type: 'string',
                desc: 'MAC地址'
            },
            {
                name: 'values',
                type: 'array',
                desc: '流量详情'
            },
            {
                name: 'traffic',
                type: 'int',
                desc: '流量数据'
            },
            {
                name: 'time',
                type: 'int',
                desc: '产生流量的具体时间'
            },
        ],
        body:
            `[
  {
      "host": "",
      "mac_addr": "",
      "values": [
          {
              "traffic": int,
              "time": int
          }
      ]
  }
]`
    },
    // {
    //   name: '/v1/oss/cp/{user_id}/bandwidth',
    //   desc: '带宽统计',
    //   method: 'get',
    // },
    {
        name: '/v1/oss/cp/{user_id}/hosts',
        desc: '添加域名',
        method: 'post',
        response: [
            {
                name: 'host',
                type: 'string',
                desc: '要添加的域名'
            }
        ],
        body: `{\n"host": "string"\n}`
    },
    {
        name: '/v1/oss/cp/{user_id}/hosts/{host_id}',
        desc: '删除域名',
        method: 'post',
        response: [
            {
                name: 'hostID',
                type: 'string',
                desc: '要删除域名的ID'
            },
        ],
        body: `{\n"hostID": "string"\n}`
    },
    {
        name: '/v1/oss/cp/{user_id}/cache/configs',
        desc: '添加缓存配置信息',
        method: 'post',
        response: [
            {
                name: 'type',
                type: 'string',
                desc: '要添加缓存的类型（文件，目录）file、dir'
            },
            {
                name: 'host',
                type: 'string',
                desc: '要添加缓存的域名'
            },
            {
                name: 'uri',
                type: 'string',
                desc: '要添加缓存的uri'
            },
            {
                name: 'popularity',
                type: 'int',
                desc: '希望要添加缓存的热度（hot, medium, cold）'
            },
            {
                name: 'encrypt',
                type: 'bool',
                desc: '分发是否加密'
            },
            {
                name: 'piece',
                type: 'bool',
                desc: '分发是否分片'
            },
            {
                name: 'piece_type',
                type: 'string',
                desc: '分片分发的类型（顺序，交叉，随机的模式）：Sequencial、Interleaved、Random'
            },
            {
                name: 'regions',
                type: 'array',
                desc: '期望文件分发的地区信息'
            },
            {
                name: 'isp',
                type: 'string',
                desc: '期望文件分发的运营商'
            },
            {
                name: 'country',
                type: 'string',
                desc: '期望文件分发的国家'
            },
            {
                name: 'province',
                type: 'string',
                desc: '期望文件分发的省份'
            },
        ],
        body:
            `{
"type": "string",
"videos": [
    {
      "scheme": "string",
      "host": "string",
      "uri": "string",
      "popularity": 0,
      "encrypt": true,
      "piece": true,
      "piece_type": "string",
      "regions": [
        {
          "isp": "string",
          "country": "string",
          "province": "string"
        }
      ]
    }
  ]
}`
    },
]
class HelpSider extends Component {
    constructor(props, context) {
        super(props, context);
        this.handleDrawerClose = this.handleDrawerClose.bind(this);
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleCollapseChange = this.handleCollapseChange.bind(this);
        this.state = {
            api: apis[0],
            open: false,
            collapse: {},
            selected: ""
        };
    }
  handleClick = (api) => () => {
    this.setState({
      api: api,
    })
  }
    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };
    handleCollapseChange = (name) => () => {
        this.setState({
            collapse: {...this.state.collapse, [name]: !this.state.collapse[name]}
            });
    };
  render() {
      const { classes } = this.props;
      const drawerList = apis.map((api, index) =>
          <ListItem key={index} button onClick={this.handleClick(api)} selected={this.state.api.desc == api.desc}>
          <ListItemText primary={api.desc}/>
        </ListItem>)
      ;



      const temporaryDrawerProps = {
          variant: "temporary",
          anchor: "left",
          classes: {
              paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          },
          open: this.state.open,
          onClose: this.handleDrawerClose
      };
      const permanentDrawerProps = {
          variant: "permanent",
          className: classes.drawer,
          anchor: "left",
          classes: {
              paper: classes.drawerPaper,
          },
          open: true
      };
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar} >
                <Toolbar className={classes.toolBarColor}>
                    <span style={{float:"left"}}>
                        <Link to="/">
                        <img src="/logo.png" alt="Logo" />
                    </Link>
                    </span>

                    <Typography variant="h5" style={{flexGrow:1}} align="center">
                        API
                    </Typography>
                </Toolbar>
            </AppBar>
            <Hidden smUp>
                <Drawer
                    {...temporaryDrawerProps}
                >
                    <div className={classes.toolbarIcon}>
                        {/*<IconButton onClick={this.handleDrawerClose}>
                                <ChevronLeftIcon />
                            </IconButton>*/}
                    </div>
                    <Divider />
                    <List>{drawerList}</List>
                </Drawer>
            </Hidden>
            <Hidden xsDown>
                <Drawer
                    {...permanentDrawerProps}
                >
                    <div className={classes.toolbarDrawer} />
                    <Divider />

                    <List>{drawerList}</List>
                </Drawer>
            </Hidden>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {/*<div className={classes.toolbarDrawer} />*/}
                <HelpContent data={this.state.api}/>
            </main>
        </div>

    )
  }
}

HelpSider.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HelpSider);