/*
 * @Descripttion: 
 * @version: 
 * @Author: Tperam
 * @Date: 2021-04-25 10:19:06
 * @LastEditors: Tperam
 * @LastEditTime: 2021-10-18 17:10:08
 */
import React, { Component } from 'react'
import './styles/footer.css'

class Footer extends Component {
  render() {
    return (
      <footer className="footer" id="footer">
        <div className="footer-container">

          <div className="footer-body">
            <div className="footer-item-logo">
              {/*<figure>
                <img src="/title_logo.png" alt="Logo" />
              </figure>*/}
              <div>
                <ul>
                    <li className="wechat-block">
                        <a>联系我们：</a>
                    </li>
                    <li className="address-block"><a>电话</a>
                    <div className="address-hide">
                      <p>
                        0755-86538007<br/>
                      </p>
                    </div>
                  </li>
                  <li className="wechat-block"><a>微信</a>
                    <div className="wechat-hide">
                      <figure>
                        <figcaption>添加微信</figcaption>
                        <img src="/assets/qrcode/wechat.jpg" alt="wechat_qrcode" />
                      </figure>
                    </div>
                  </li>
                  <li className="mail-block"><a>邮箱</a>
                    <div className="mail-hide">
                      <p>service@pear.hk</p>                    
                    </div>
                  </li>
                  <li className="address-block"><a href="https://j.map.baidu.com/5zrvP" target="_blank" rel="noopener noreferrer">地址</a>
                    <div className="address-hide">
                      <p>深圳：南山区学苑大道南山智园C2栋1610</p><br />
                      <p>香港：新界沙田香港科學園科技大道西10號浚湖樓2號612</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            
          </div>
          <div>
            <div className="footer-words">
              <p>
                <a> 增值电信业务经营许可证 B1-20180826</a>&nbsp;&nbsp;&nbsp;&nbsp;
                <a> 统一社会信用代码 91440300MA5EDRDG7X</a>&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <div>
                <img src="/assets/备案图标.png" alt="备案图标"/>
                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502002232" rel="noopener noreferrer"> 粤公网安备 {window.location.host.indexOf('pear') > -1 ? 44030502002232 : 44030502002264}号</a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a target="_blank" href="https://beian.miit.gov.cn/" rel="noopener noreferrer">粤ICP备15114601号</a>

              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
