import React, { Component } from "react"

class Desc extends Component {
  changeImg = (id) => {
    const pic = document.getElementById(`desc${id}`)
    pic.src = `/assets/desc/${id}_gold.svg`
  }
  mouseOut = (id) => {
    const pic = document.getElementById(`desc${id}`)
    pic.src = `/assets/desc/${id}_gray.svg`
  }
  render() {
    return (
      <div className="desc">
        <div className="desc-title">
          <h1>全球唯一的雾计算服务商</h1>
          <p>Fog CDN、Fog + Cloud，百万节点赋予未来更多可能</p>
        </div>
        <div className="desc-main">
          <div className="desc-item" onMouseOver={() => this.changeImg(1)} onMouseOut={() => this.mouseOut(1)}>
            <div className="desc-item-top">
              <img id="desc1" src="/assets/desc/1_gray.svg" alt="" />
            </div>
            <div className="desc-item-bot">
              <h4>百万节点 自然分布</h4>
              <p>Pear拥有百万量级的雾节点，覆盖全国各省市，均衡度接近CNNIC统计的网民分布，并依此构建起强大的雾网络。</p>
            </div>
          </div>

          <div className="desc-item"  onMouseOver={() => this.changeImg(2)} onMouseOut={() => this.mouseOut(2)}>
            <div className="desc-item-top">
              <img id="desc2" src="/assets/desc/2_gray.svg" alt="" />
            </div>
            <div className="desc-item-bot">
              <h4>稳定持续 统一调度</h4>
              <p>雾节点均为高性能的智能硬件，平均在线时长23小时、平均上行带宽32Mbps、平均存储空间1.5TB，独有的资源调度平台屏蔽底层硬件资源的差异，一键调度所有平台设备。</p>
            </div>
          </div>
          <div className="desc-item"  onMouseOver={() => this.changeImg(3)} onMouseOut={() => this.mouseOut(3)}>
            <div className="desc-item-top">
              <img id="desc3" src="/assets/desc/3_gray.svg" alt="" />
            </div>
            <div className="desc-item-bot">
              <h4>真实环境 贴近用户</h4>
              <p>所有节点均来自家庭或企业网络设备（智能路由器，NAS），贴近用户。精心打造五年的雾节点调度算法，同运营商、同区域，局域网内调度，零延迟，单链路最高可提升至Gbps。</p>
            </div>
          </div>
          <div className="desc-item"  onMouseOver={() => this.changeImg(4)} onMouseOut={() => this.mouseOut(4)}>
            <div className="desc-item-top">
              <img id="desc4" src="/assets/desc/4_gray.svg" alt="" />
            </div>
            <div className="desc-item-bot">
              <h4>共享资源 融合未来</h4>
              <p>通过共享雾计算高效利用家庭用户闲置的带宽、存储、计算等资源，高效稳定，大幅降低云服务成本。</p>
            </div>
          </div>
          <div className="desc-item"  onMouseOver={() => this.changeImg(5)} onMouseOut={() => this.mouseOut(5)}>
            <div className="desc-item-top">
              <img id="desc5" src="/assets/desc/5_gray.svg" alt="" />
            </div>
            <div className="desc-item-bot">
              <h4>开放合作 快速落地</h4>
              <p>Pear一直秉持开放、合作、共享的理念，经过众多客户的多年实际生产环境检验，实现一整套共享雾计算系统的快速完整交付。</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Desc
