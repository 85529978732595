import React, { Component } from "react"
import { Link } from 'react-router-dom'

class Pandect extends Component {
  changeImg = (id) => {
    const pic = document.getElementById(`pandect${id}`)
    pic.src = `/assets/pandect/${id}_gold.svg`
    const item = document.getElementsByClassName('pandect-item')[id-1]
    item.style.backgroundColor = '#fff'
  }
  mouseOut = (id) => {
    const pic = document.getElementById(`pandect${id}`)
    pic.src = `/assets/pandect/${id}_gray.svg`
    const item = document.getElementsByClassName('pandect-item')[id-1]
    item.style.backgroundColor = '#f1f4f8'
  }
  render() {
    return (
      <div className="pandect-container">
      <div className="pandect-box">
        <div className="pandect-item" onMouseOver={() => this.changeImg(1)} onMouseOut={() => this.mouseOut(1)}>
            <Link to="/about">
            <div className="item-left">
              <img id="pandect1" src="/assets/pandect/1_gray.svg" alt="" />
            </div>
            <div className="item-right">
              <h3>梨享雾计算</h3>
              <p>全球共享雾计算创领者</p>
            </div>
            </Link>
        </div>
        <div className="pandect-item" onMouseOver={() => this.changeImg(2)} onMouseOut={() => this.mouseOut(2)}>
          <a href="https://i.openfogos.com" target="_blank">
            <div className="item-left">
              <img id="pandect2" src="/assets/pandect/2_gray.svg" alt="" />
            </div>
            <div className="item-right">
              <h3>OpenFogOS</h3>
              <p>利用闲置设备赚取收益</p>
            </div>
          </a>
        </div>
        <div className="pandect-item" onMouseOver={() => this.changeImg(3)} onMouseOut={() => this.mouseOut(3)}>
          <Link to="/fogCDN">
            <div className="item-left">
              <img id="pandect3" src="/assets/pandect/3_gray.svg" alt="" />
            </div>
            <div className="item-right">
              <h3>Fog CDN</h3>
              <p>无限内容尽在身边</p>
            </div>
          </Link>
        </div>
        <div className="pandect-item" onMouseOver={() => this.changeImg(4)} onMouseOut={() => this.mouseOut(4)}>
          <a href="https://demo.webrtc.win" target="_blank">
            <div className="item-left">
              <img id="pandect4" src="/assets/pandect/4_gray.svg" alt="" />
            </div>
            <div className="item-right">
              <h3>解决方案</h3>
              <p>Fog+ 无限可能</p>
            </div>
          </a>
        </div>
        {/*<div className="pandect-item" style={{border: 'none'}} onMouseOver={() => this.changeImg(4)} onMouseOut={() => this.mouseOut(4)}>
          <a>
            <div className="item-left">
              <img id="pandect4" src="/assets/pandect/4_gray.svg" alt="" />
            </div>
            <div className="item-right">
              <h3>Fog + BlockChain</h3>
              <p>区块链赋能雾计算</p>
            </div>
          </a>
        </div>*/}
        </div>
      </div>
    )
  }
}

export default Pandect
