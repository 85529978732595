import React, { Component } from 'react'
// import marked from 'marked'；
import ReactMarkdown from 'react-markdown';

export default class Content extends Component {
  render() {
    return (
      <div className="content-body" id="mark">
          <ReactMarkdown source={this.props.data}/>
      </div>
    )
  }
  /*componentDidMount() {
    document.getElementById('mark').innerHTML = marked(this.props.data)
  }
  componentWillReceiveProps(newProps) {
    document.getElementById('mark').innerHTML = marked(newProps.data)
  }*/
}
