import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import './styles/top.css'
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import LoginIcon from '@material-ui/icons/AccountCircle';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
  alignCenter: {
    textAlign: "center",
  },
  toolBar: {
    justifyContent: "space-around"
  },
  menuButton: {
    // marginLeft: -12,
    // marginRight: 20,
  },
  list: {
    width: 250,
  },
  golden: {
    color: '#a68540',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    // paddingTop: theme.spacing(1),
    marginLeft: -theme.spacing(2),
    display: "block",
    // marginBottom: theme.spacing(1),
  },
  logoLink: {
    // paddingTop: theme.spacing(1),
    display: "block",
    height: theme.spacing(6),
    // marginBottom: theme.spacing(1),
  },
  fullList: {
    width: 'auto',
  },
  drawerItem2: {
    paddingLeft: theme.spacing(5),
  },
});


class TopBar extends Component {
  constructor(props) {
    super(props);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleCollapseChange = this.handleCollapseChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      menuOpen: false,
      collapse: {},
      selected: ""
    }
  };
  handleMenuClick = () => {
    this.setState({
      menuOpen: !this.state.menuOpen
    })
  };
  handleCollapseChange = (name) => () => {
    this.setState({
      collapse: {...this.state.collapse, [name]: !this.state.collapse[name]}
    });
  };
  handleClick = (title) => () => {
    this.setState({
      selected: title,
      menuOpen: !this.state.menuOpen
    })
  };
  render() {
    const {classes} = this.props;
    return (
      <div>
        <Hidden smUp>
          <AppBar position="static" color="secondary" className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
              <h1 className={classNames(classes.grow, classes.golden, classes.logo)}>
                <Link className={classes.logoLink} to="/">
                  <img src="/logo.png" alt="Logo"/>
                </Link>
              </h1>
              <IconButton className={classNames(classes.menuButton, classes.golden)} aria-label="Menu" onClick={this.handleMenuClick}>
                <MenuIcon/>
              </IconButton>
            </Toolbar>
          </AppBar>
          <SwipeableDrawer
            anchor="top"
            open={this.state.menuOpen}
            onClose={this.handleMenuClick}
            onOpen={this.handleMenuClick}
          >
            <div
              className={classes.fullList}
              role="presentation"
              onKeyDown={this.handleMenuClick}
            >
              <List>
                <ListItem className="listItem" button onClick={this.handleCollapseChange("title_1")} className={classes.drawerItem}>
                  <ListItemText className="listItem" primary="产品方案"/>
                  {this.state.collapse["title_1"] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.collapse["title_1"]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding onClick={this.handleDrawerClose}>
                    <Link to="/fogCDN">
                      <ListItem button onClick={this.handleClick("FogCDN")} className={classes.drawerItem2} selected={this.state.selected == "FogCDN"}>
                        <ListItemText primary="Fog CDN"/>
                      </ListItem>
                    </Link>
                    <a href="https://demo.webrtc.win/player" target="_blank" rel="noopener noreferrer">
                      <ListItem button onClick={this.handleClick("点播")} className={classes.drawerItem2} selected={this.state.selected == "点播"}>
                        <ListItemText primary="点播"/>
                      </ListItem>
                    </a>
                    <a href="https://demo.webrtc.win/downloader" target="_blank" rel="noopener noreferrer">
                      <ListItem button onClick={this.handleClick("下载")} className={classes.drawerItem2} selected={this.state.selected == "下载"}>
                        <ListItemText primary="下载"/>
                      </ListItem>
                    </a>
                    <a href="https://demo.webrtc.win/hlsjs" target="_blank" rel="noopener noreferrer">
                      <ListItem button onClick={this.handleClick("直播")} className={classes.drawerItem2} selected={this.state.selected == "直播"}>
                        <ListItemText primary="直播"/>
                      </ListItem>
                    </a>
                    <Link to="/goods/pear-station">
                      <ListItem button onClick={this.handleClick("Pear-Station")} className={classes.drawerItem2} selected={this.state.selected == "Pear-Station"}>
                        <ListItemText primary="Pear-Station"/>
                      </ListItem>
                    </Link>

                  </List>
                </Collapse>
                <ListItem className="listItem" button onClick={this.handleCollapseChange("title_2")} className={classes.drawerItem}>
                  <ListItemText className="listItem" primary="服务支持"/>
                  {this.state.collapse["title_2"] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.collapse["title_2"]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding onClick={this.handleDrawerClose}>
                    <Link to="/api">
                      <ListItem button onClick={this.handleClick("API")} className={classes.drawerItem2} selected={this.state.selected == "API"}>
                        <ListItemText primary="API"/>
                      </ListItem>
                    </Link>
                    <a href="https://github.com/PearInc" target="_blank" rel="noopener noreferrer">
                      <ListItem button onClick={this.handleClick("开源社区")} className={classes.drawerItem2} selected={this.state.selected == "开源社区"}>
                        <ListItemText primary="开源社区"/>
                      </ListItem>
                    </a>
                    <a href="#footer">
                      <ListItem button onClick={this.handleClick("联系我们")} className={classes.drawerItem2} selected={this.state.selected == "联系我们"}>
                        <ListItemText primary="联系我们"/>
                      </ListItem>
                    </a>
                  </List>
                </Collapse>
                <ListItem className="listItem" button onClick={this.handleCollapseChange("title_3")} className={classes.drawerItem}>
                  <ListItemText className="listItem" primary="关于梨享"/>
                  {this.state.collapse["title_3"] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.collapse["title_3"]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding onClick={this.handleDrawerClose}>
                    <Link to="/about">
                      <ListItem button onClick={this.handleClick("公司简介")} className={classes.drawerItem2} selected={this.state.selected == "公司简介"}>
                        <ListItemText primary="公司简介"/>
                      </ListItem>
                    </Link>
                  </List>
                </Collapse>
                <ListItem className="listItem" button onClick={this.handleCollapseChange("title_4")} className={classes.drawerItem}>
                  <ListItemText className="listItem" primary="新闻动态"/>
                  {this.state.collapse["title_4"] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.collapse["title_4"]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding onClick={this.handleDrawerClose}>
                    <a href="/#news">
                      <ListItem button onClick={this.handleClick("官方新闻")} className={classes.drawerItem2} selected={this.state.selected == "官方新闻"}>
                        <ListItemText primary="官方新闻"/>
                      </ListItem>
                    </a>
                    <a href="/#news">
                      <ListItem button onClick={this.handleClick("媒体报道")} className={classes.drawerItem2} selected={this.state.selected == "媒体报道"}>
                        <ListItemText primary="媒体报道"/>
                      </ListItem>
                    </a>
                  </List>
                </Collapse>
                <ListItem className="listItem" button onClick={this.handleCollapseChange("title_5")} className={classes.drawerItem}>
                  <ListItemText className="listItem" primary="加入我们"/>
                  {this.state.collapse["title_5"] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.collapse["title_5"]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding onClick={this.handleDrawerClose}>
                    <a href="https://www.lagou.com/gongsi/j582782.html" target="_blank" rel="noopener noreferrer">
                      <ListItem button onClick={this.handleClick("社会招聘")} className={classes.drawerItem2} selected={this.state.selected == "社会招聘"}>
                        <ListItemText primary="社会招聘"/>
                      </ListItem>
                    </a>
                  </List>
                </Collapse>
                <a className="header-signin" href="https://account.pear.link">
                  <ListItem className="listItem" button  className={classes.drawerItem}>
                    <ListItemText className="listItem" primary="登录"/>
                  </ListItem>
                </a>

              </List>
            </div>
          </SwipeableDrawer>
        </Hidden>
        <Hidden xsDown>
          <AppBar position="static" color="secondary" className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
              <div className="header-left">
                <Link to="/">
                  <img src="/logo.png" alt="Logo"/>
                </Link>
              </div>
              <div>
                <div className="header-center">
                  <ul>
                    <li>
                      <a className="header-center-link">产品方案</a>
                      <div className="header-hide">
                        <ul>
                          <li><Link to="/fogCDN">Fog CDN</Link></li>
                          <li><a href="https://demo.webrtc.win/player" target="_blank" rel="noopener noreferrer">点播</a>
                          </li>
                          <li><a href="https://demo.webrtc.win/downloader" target="_blank"
                                 rel="noopener noreferrer">下载</a></li>
                          <li><a href="https://demo.webrtc.win/hlsjs" target="_blank" rel="noopener noreferrer">直播</a></li>
                          <li><Link to="/goods/pear-station">Pear-Station</Link></li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <a className="header-center-link">服务支持</a>
                      <div className="header-hide">
                        <ul>
                          <li><Link to="/api">API</Link></li>
                          <li><a href="https://github.com/PearInc" target="_blank" rel="noopener noreferrer">开源社区</a>
                          </li>
                          <li><a href="#footer">联系我们</a></li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <a className="header-center-link">关于梨享</a>
                      <div className="header-hide">
                        <ul>
                          <li><Link to="/about">公司简介</Link></li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <a className="header-center-link">新闻动态</a>
                      <div className="header-hide">
                        <ul>
                          <li><a href="/#news">官方新闻</a></li>
                          <li><a href="/#news">媒体报道</a></li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <a className="header-center-link">加入我们</a>
                      <div className="header-hide">
                        <ul>
                          <li><a href="https://www.lagou.com/gongsi/j582782.html" target="_blank"
                                 rel="noopener noreferrer">社会招聘</a></li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="header-right">
                {/*<ul>
                <li><a className="header-signin" href="https://account.pear.link">登录</a></li>
                <li><a className="header-contact" href="https://account.pear.link/signup">注册</a></li>

              </ul>*/}
                <Tooltip title={"登录后可享受梨享全平台的服务"}>
                  <a className="header-signin" href="https://account.pear.link">
                    <Button color="primary" style={{fontSize: "1.05rem"}}>
                      <LoginIcon color="primary" style={{fontSize: 40}} className={classes.leftIcon}/>
                      登录
                    </Button>
                  </a>
                </Tooltip>
              </div>
            </Toolbar>
          </AppBar>
        </Hidden>
      </div>


    )
  }
}

export default withStyles(styles)(TopBar)
