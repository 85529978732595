import React, { Component } from 'react'
import '../../common/styles/footer.css'

class Concat extends Component {
  render() {
    return (
      <section className="concat">
        <div className="concat-container">
          <div className="concat-main">
            <h4>联系商务，试用咨询</h4>
            <a>联系我们</a>
          </div>
        </div>
      </section>
    )
  }
}

export default Concat
