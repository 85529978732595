import React, { Component } from 'react'
// import { Layout } from 'antd'
// import CDNSider from './sider'
import CDNContent from './content'
import './index.css'
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Top from '../../common/top';

// const { Sider, Content } = Layout
const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
    toolbarDrawer: theme.mixins.toolbar,
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
        backgroundColor: "#222",
        color: "#a68540",
        height: '73px',
    },
    toolBarColor: {
        backgroundColor: "#222",
        color: "#a68540",
        height: '73px',
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    menuButtonHidden: {
        display: 'none',
    },
    drawerItem: {
        // paddingLeft: theme.spacing(3),
    },
    drawerItem2: {
        paddingLeft: theme.spacing(5),
    },
    drawerItem3: {
        paddingLeft: theme.spacing(8),
    },
    drawerItem4: {
        paddingLeft: theme.spacing(11),
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        // position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    // content: {
    //     flexGrow: 1,
    //     padding: theme.spacing(3),
    //     height: '100vh',
    //     overflow: 'auto',
    // },
    chartContainer: {
        marginLeft: -22,
    },
    tableContainer: {
        height: 320,
    },
    h5: {
        marginBottom: theme.spacing(2),
    },
    a: {
        textDecoration:'none',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
});

const data = [
    {
        title: "产品简介",
        content: `# 产品简介 #
  基于Pear自建的雾计算平台，Pear打造了开放的Fog CDN产品，充分挖掘和利用边缘网络海量闲置资源，为互联网企业提供全面稳定的CDN服务，适用于点播，直播，文件下载等业务场景。

  ![产品简介](/assets/fogCDN/cdn简介.png)
  `
    },
    {
        title: "CDN部署",
        content: `# CDN部署 #
  截止2018年7月，Fog CDN 在全国各省份部署40W+雾节点，覆盖电信，联通，移动及几十家中小型运营商，节点平均上行带宽30Mbps,平均存储1.5TB。

  ![CDN部署](/assets/fogCDN/cdn部署.png)
  `
    },
    {
        title: "Fog CDN的优势",
        content: `# Fog CDN的优势 #

## 技术领先 ##
  + 自主研发业内领先的节点调度系统，支持千万量级节点。
  + 局域网优先，自动选择最优路径。
  + 内容根据热度自动分发更新。


## 成熟稳定 ##
  + 经历腾讯视频，腾讯云等多方业务的实际场景验证。
  + 每天超过1亿用户使用。


## 体验优质 ##
  + 支持主流终端平台，主流内容格式的内容加速分发。
  + 首屏延迟1S内，零卡顿，各项用户指标优于传统CDN。
  + 实时收集全网节点质量，帮助用户获得最佳访问路径。


## 接入简便 ##
  + 一行URL配置资源，全网自动分布。
  + 一行JS代码，无插件Web应用集成。
  + 像使用HTTP协议一样，使用Fog CDN SDK。


## 计费灵活 ##
  + 支持多种计费模式，根据业务需求和业务特征灵活调整。
  + 成本低廉，最高可降至云CDN的一半成本。


## 灵活配置 ##
  + 提供调度策略等配置选项，灵活控制CDN。
  + 开放开发接口，定制专属服务。
  + 丰富的统计报表和日志信息，帮助进行业务决策。

  `
    },
    {
        title: "方案概览",
        content: `# 方案概览 #
  Fog CDN产品为了解决复杂多变的网络环境下的静态，动态，流媒体等内容访问速度慢访问可用性不高的普遍问题，通过Pear部署在全国各地的雾节点辅以涵盖全局的调度、监控系统组成了CDN网络，配备完善的运营管理系统，建成了涵盖“管理——内容——监控”的Fog CDN架构平台，平台架构如图所示。

  ![方案概览](/assets/fogCDN/cdn方案概览.png)
  `
    },
    {
        title: "雾存储引擎",
        content: `# 雾存储引擎 #
  针对不同的服务场景，Fog CDN配置不同的存储策略。在提供足够的加速性能的同时，保证CDN节点的命中率。但是目前广泛使用的CDN存储访问策略不适用于现在的雾节点资源，无法在服务时提供理想的效果，无法兼顾IO性能和加速效果，这限制了雾节点的服务能力。  
  Fog Storage Engine 是Pear自研的第二代存储引擎，目前部署于线上所有的雾节点中。
  `
    },
    {
        title: "文件分片存储",
        content: `# 文件分片存储 #
  CDN常用的存储策略为一份文件完整存储于多个CDN节点上，即每个节点具有完整的文件。即使分片，也只是为了方便于文件的存储与更新。然而利用FSE，所有文件都会被分成一个个小数据分片存储在不同的节点上，如此以来，提升了缓存文件的稳定性，不会存在为了存储一个4KB的小文件而将一个旧的1GB文件全部删除；提升了分发效率，相当于存在多个子流树进行文件的分发（每个分片组为一个子流）；降低系统开销，数据直接写到缓存中。
  另外，使用文件分片存储可以显著提升文件的安全性，任意单独节点存储的文件都不能组成完整的源文件。必须要拿到全网所有的设备上的所有文件，再筛选出具有某文件的节点，按照特定的策略进行组合，才能复原一个完整的视频文件。如此以来，破解整个雾节点网络的成本远高于直接破解中心调度服务器的成本，所以文件分片存储也使得存储在用户贡献出的雾节点上的文件更加安全。
  `
    },
    {
        title: "超热点数据提取",
        content: `# 超热点数据提取 #
  Pear 发现在CDN使用场景下依旧遵循巴莱多定律（二八法则），即80%的访问量集中在20%的热门数据中。超热点数据频繁的被读写，占据了大多数的IO开销，同时这些超热点数据与正常数据存储在同一节点上，影响了正常文件数据的读取。因此FSE自动识别超热点数据将其读取并常驻在内存当中，而正常数据还是依旧存储在数据存储介质中，一方面使得超热数据服务更快，一方面极大程度上规避了超热数据的IO开销。  
  `
    },
    {
        title: "容灾备份",
        content: `# 容灾备份 #
  在海量节点，高强度（7*24小时）CDN的服务场景下，节点会有一定概率故障，以及因为分布在用户家庭之中，节点自身就有一定概率离线。FSE 支持将无法正常工作的节点自动从缓存中剔除（离线状态），已经离线的节点不会中断业务的正常运行。节点离线后，缓存的文件会在其他的节点上重新DHT（分布式哈希表）分布，不影响正常文件存取。该功能配合文件的分片存储以及Pear自研的针对于雾计算场景下的节点动态负载均衡机制，能将节点离线造成的影响降到最低。
  `
    },
    {
        title: "与云CDN协同",
        content: `# 与云CDN协同 #
  Pear学习了传统CDN的缓存架构并进一步发展，独创雾CDN与云CDN协同模式，用云CDN 作为内容提供商分发的源站，内容提供商仅需提供URL以及分发参数，Fog CDN就会自动去云端获取，下载，分发视频。在用户观看视频时，用原先的URL向Pear后台发送请求，Pear将正常调用Fog CDN，如果遇到网络状况较差或者紧急请求则自动向云CDN获取数据，确保用户体验。  
  方案能将云与雾融合，一方面可以满足内容提供厂商的从云到雾用户零感知的无缝切换，可以在保持使用原有的云CDN服务不变的情况下，逐步过渡采用Fog CDN解决方案；另一方面可以帮助传统云CDN厂商快速拥有Fog CDN能力，与云CDN业务紧密结合，降低了成本，提供更优质的CDN服务。  
  ![与云CDN协同](/assets/fogCDN/cdn协同.png)
  
  `
    },
    {
        title: "GFLB",
        content: `
  GFLB，全称Global Fog nodes Load Balance(全球雾节点负载均衡调度系统), 是一套实现全网接入，全平台接入，支持局域网内调度，最短链路调度，自动负载均衡的系统。
  `
    },
    {
        title: "DWRA动态加权轮询",
        content: `
  相对于传统CDN，P2P系统节点数量庞大，节点性能弱于服务器，节点间性能差异大且不稳定。传统的CDN负载均衡算法在雾计算场景下很难达到良好的系统负载均衡度。DWRA（Dynamic Weighted Round Robin Algorithm，动态加权轮询算法）在用户建立连接时，根据节点的权重和状态信息选取一个轮询序列，客户端根据序列建立连接；对于已经建立连接的用户数据，根据会话信息，及时更新节点状态。这样就达到了雾节点负载均衡的目的。  
  除了达到负载均衡以外，DWRA还针对雾计算场景增加了熔断机制。因为雾节点数目庞大，且调度服务器无法实时获得所有节点的负载情况，很容易出现某个节点被过度调用，超过节点的承载范围，进而影响服务质量。DWRA独有的熔断机制可以估算每个节点的负载程度，计算节点利用率，当负载程度超过设定阈值时，不再将此节点计入返回的轮询序列中。
  `
    },
    {
        title: "最短链路",
        content: `
  当用户发出请求时，传统CDN只需返回用户所属地区服务器资源来提供服务即可。Fog CDN与传统CDN节点调度模式有共通之处，即返回离用户最近的雾节点即可，但节点调度难度远高于传统CD。因为雾节点更加贴近用户，节点网络状态多样，同一客户端连接不同节点的连通率，同一节点连接不同的客户端的连通率都存在着巨大差异。传统CDN的资源调度和负载已经远不能满足雾计算时代CDN的需求。  
  Pear依托自身在雾计算领域多年的积累，实现了精确到LAN级的节点调度策略，用户在发出请求后，将优先局域网，同区域，同运营商，雾节点资源最短链路调度。实现了ms级响应延迟，用户带宽能力放大，大大缓解了骨干网的压力，加速了全面4K视频时代的到来。
  `
    },
]
const cdnData = {
  产品简介: `# 产品简介 #
  基于Pear自建的雾计算平台，Pear打造了开放的Fog CDN产品，充分挖掘和利用边缘网络海量闲置资源，为互联网企业提供全面稳定的CDN服务，适用于点播，直播，文件下载等业务场景。

  ![产品简介](/assets/fogCDN/cdn简介.png)
  `,
  CDN部署: `# CDN部署 #
  截止2018年7月，Fog CDN 在全国各省份部署40W+雾节点，覆盖电信，联通，移动及几十家中小型运营商，节点平均上行带宽30Mbps,平均存储1.5TB。

  ![CDN部署](/assets/fogCDN/cdn部署.png)
  `,

  FogCDN的优势: `# Fog CDN的优势 #

## 技术领先 ##
  + 自主研发业内领先的节点调度系统，支持千万量级节点。
  + 局域网优先，自动选择最优路径。
  + 内容根据热度自动分发更新。


## 成熟稳定 ##
  + 经历腾讯视频，腾讯云等多方业务的实际场景验证。
  + 每天超过1亿用户使用。


## 体验优质 ##
  + 支持主流终端平台，主流内容格式的内容加速分发。
  + 首屏延迟1S内，零卡顿，各项用户指标优于传统CDN。
  + 实时收集全网节点质量，帮助用户获得最佳访问路径。


## 接入简便 ##
  + 一行URL配置资源，全网自动分布。
  + 一行JS代码，无插件Web应用集成。
  + 像使用HTTP协议一样，使用Fog CDN SDK。


## 计费灵活 ##
  + 支持多种计费模式，根据业务需求和业务特征灵活调整。
  + 成本低廉，最高可降至云CDN的一半成本。


## 灵活配置 ##
  + 提供调度策略等配置选项，灵活控制CDN。
  + 开放开发接口，定制专属服务。
  + 丰富的统计报表和日志信息，帮助进行业务决策。

  `,
  方案概览: `# 方案概览 #
  Fog CDN产品为了解决复杂多变的网络环境下的静态，动态，流媒体等内容访问速度慢访问可用性不高的普遍问题，通过Pear部署在全国各地的雾节点辅以涵盖全局的调度、监控系统组成了CDN网络，配备完善的运营管理系统，建成了涵盖“管理——内容——监控”的Fog CDN架构平台，平台架构如图所示。

  ![方案概览](/assets/fogCDN/cdn方案概览.png)
  `,
  雾存储引擎: `# 雾存储引擎 #
  针对不同的服务场景，Fog CDN配置不同的存储策略。在提供足够的加速性能的同时，保证CDN节点的命中率。但是目前广泛使用的CDN存储访问策略不适用于现在的雾节点资源，无法在服务时提供理想的效果，无法兼顾IO性能和加速效果，这限制了雾节点的服务能力。  
  Fog Storage Engine 是Pear自研的第二代存储引擎，目前部署于线上所有的雾节点中。
  `,
  文件分片存储: `# 文件分片存储 #
  CDN常用的存储策略为一份文件完整存储于多个CDN节点上，即每个节点具有完整的文件。即使分片，也只是为了方便于文件的存储与更新。然而利用FSE，所有文件都会被分成一个个小数据分片存储在不同的节点上，如此以来，提升了缓存文件的稳定性，不会存在为了存储一个4KB的小文件而将一个旧的1GB文件全部删除；提升了分发效率，相当于存在多个子流树进行文件的分发（每个分片组为一个子流）；降低系统开销，数据直接写到缓存中。
  另外，使用文件分片存储可以显著提升文件的安全性，任意单独节点存储的文件都不能组成完整的源文件。必须要拿到全网所有的设备上的所有文件，再筛选出具有某文件的节点，按照特定的策略进行组合，才能复原一个完整的视频文件。如此以来，破解整个雾节点网络的成本远高于直接破解中心调度服务器的成本，所以文件分片存储也使得存储在用户贡献出的雾节点上的文件更加安全。
  `,
  超热点数据提取: `# 超热点数据提取 #
  Pear 发现在CDN使用场景下依旧遵循巴莱多定律（二八法则），即80%的访问量集中在20%的热门数据中。超热点数据频繁的被读写，占据了大多数的IO开销，同时这些超热点数据与正常数据存储在同一节点上，影响了正常文件数据的读取。因此FSE自动识别超热点数据将其读取并常驻在内存当中，而正常数据还是依旧存储在数据存储介质中，一方面使得超热数据服务更快，一方面极大程度上规避了超热数据的IO开销。  
  `,
  容灾备份: `# 容灾备份 #
  在海量节点，高强度（7*24小时）CDN的服务场景下，节点会有一定概率故障，以及因为分布在用户家庭之中，节点自身就有一定概率离线。FSE 支持将无法正常工作的节点自动从缓存中剔除（离线状态），已经离线的节点不会中断业务的正常运行。节点离线后，缓存的文件会在其他的节点上重新DHT（分布式哈希表）分布，不影响正常文件存取。该功能配合文件的分片存储以及Pear自研的针对于雾计算场景下的节点动态负载均衡机制，能将节点离线造成的影响降到最低。
  `,
  与云CDN协同: `# 与云CDN协同 #
  Pear学习了传统CDN的缓存架构并进一步发展，独创雾CDN与云CDN协同模式，用云CDN 作为内容提供商分发的源站，内容提供商仅需提供URL以及分发参数，Fog CDN就会自动去云端获取，下载，分发视频。在用户观看视频时，用原先的URL向Pear后台发送请求，Pear将正常调用Fog CDN，如果遇到网络状况较差或者紧急请求则自动向云CDN获取数据，确保用户体验。  
  方案能将云与雾融合，一方面可以满足内容提供厂商的从云到雾用户零感知的无缝切换，可以在保持使用原有的云CDN服务不变的情况下，逐步过渡采用Fog CDN解决方案；另一方面可以帮助传统云CDN厂商快速拥有Fog CDN能力，与云CDN业务紧密结合，降低了成本，提供更优质的CDN服务。  
  ![与云CDN协同](/assets/fogCDN/cdn协同.png)
  
  `,
  GFLB: `
  GFLB，全称Global Fog nodes Load Balance(全球雾节点负载均衡调度系统), 是一套实现全网接入，全平台接入，支持局域网内调度，最短链路调度，自动负载均衡的系统。
  `,
  DWRA动态加权轮询: `
  相对于传统CDN，P2P系统节点数量庞大，节点性能弱于服务器，节点间性能差异大且不稳定。传统的CDN负载均衡算法在雾计算场景下很难达到良好的系统负载均衡度。DWRA（Dynamic Weighted Round Robin Algorithm，动态加权轮询算法）在用户建立连接时，根据节点的权重和状态信息选取一个轮询序列，客户端根据序列建立连接；对于已经建立连接的用户数据，根据会话信息，及时更新节点状态。这样就达到了雾节点负载均衡的目的。  
  除了达到负载均衡以外，DWRA还针对雾计算场景增加了熔断机制。因为雾节点数目庞大，且调度服务器无法实时获得所有节点的负载情况，很容易出现某个节点被过度调用，超过节点的承载范围，进而影响服务质量。DWRA独有的熔断机制可以估算每个节点的负载程度，计算节点利用率，当负载程度超过设定阈值时，不再将此节点计入返回的轮询序列中。
  `,
  最短链路: `
  当用户发出请求时，传统CDN只需返回用户所属地区服务器资源来提供服务即可。Fog CDN与传统CDN节点调度模式有共通之处，即返回离用户最近的雾节点即可，但节点调度难度远高于传统CD。因为雾节点更加贴近用户，节点网络状态多样，同一客户端连接不同节点的连通率，同一节点连接不同的客户端的连通率都存在着巨大差异。传统CDN的资源调度和负载已经远不能满足雾计算时代CDN的需求。  
  Pear依托自身在雾计算领域多年的积累，实现了精确到LAN级的节点调度策略，用户在发出请求后，将优先局域网，同区域，同运营商，雾节点资源最短链路调度。实现了ms级响应延迟，用户带宽能力放大，大大缓解了骨干网的压力，加速了全面4K视频时代的到来。
  `,
}

class FogCDN extends Component {
    constructor(props, context) {
        super(props, context);
        this.handleDrawerClose = this.handleDrawerClose.bind(this);
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleCollapseChange = this.handleCollapseChange.bind(this);
        this.state = {
            data: data[0].content,
            open: false,
            collapse: {},
            selected: ""
        };
    }
  handleClick = (title) => () => {
    this.setState({
      data: cdnData[title],
        selected: title
    })
  }
    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };
    handleCollapseChange = (name) => () => {
        this.setState({
            collapse: {...this.state.collapse, [name]: !this.state.collapse[name]}
            });
    };
  render() {
      const { classes } = this.props;
      const drawerList1 = (<div>
          {data.map((item, index)=>
              <ListItem key={index} button onClick={this.handleClick(index)} className={classes.drawerItem2}>
                <ListItemText primary={item.title}/>
              </ListItem>)}
      </div>);
      const drawerList = (<div>
          <ListItem className="listItem" button onClick={this.handleCollapseChange("title_1")} className={classes.drawerItem}>
              <ListItemText className="listItem" primary="Fog CDN"/>
              {this.state.collapse["title_1"] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.collapse["title_1"]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding onClick={this.handleDrawerClose}>
                  <ListItem button onClick={this.handleClick("产品简介")} className={classes.drawerItem2} selected={this.state.selected == "产品简介"}>
                      <ListItemText primary="产品简介"/>
                  </ListItem>
                  <ListItem button onClick={this.handleClick("CDN部署")} className={classes.drawerItem2} selected={this.state.selected == "CDN部署"}>
                      <ListItemText primary="CDN部署"/>
                  </ListItem>
                  <ListItem button onClick={this.handleClick("FogCDN的优势")} className={classes.drawerItem2} selected={this.state.selected == "FogCDN的优势"}>
                      <ListItemText primary="Fog CDN的优势"/>
                  </ListItem>
              </List>
          </Collapse>
          <ListItem className="listItem" button onClick={this.handleCollapseChange("title_2")} className={classes.drawerItem}>
              <ListItemText className="listItem" primary="方案架构"/>
              {this.state.collapse["title_2"] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.collapse["title_2"]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding onClick={this.handleDrawerClose}>
                  <ListItem button onClick={this.handleClick("方案概览")} className={classes.drawerItem2} selected={this.state.selected == "方案概览"}>
                      <ListItemText primary="方案概览"/>
                  </ListItem>
                  <ListItem className="listItem" button onClick={this.handleCollapseChange("title_3")} className={classes.drawerItem2}>
                      <ListItemText className="listItem" primary="内容系统"/>
                      {this.state.collapse["title_3"] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={this.state.collapse["title_3"]} timeout="auto" unmountOnExit>
                      <ListItem className="listItem" button onClick={this.handleCollapseChange("title_4")} className={classes.drawerItem3}>
                          <ListItemText className="listItem" primary="雾存储引擎"/>
                          {this.state.collapse["title_4"] ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={this.state.collapse["title_4"]} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding onClick={this.handleDrawerClose}>
                              <ListItem button onClick={this.handleClick("文件分片存储")} className={classes.drawerItem4} selected={this.state.selected == "文件分片存储"}>
                                  <ListItemText primary="文件分片存储"/>
                              </ListItem>
                              <ListItem button onClick={this.handleClick("超热点数据提取")} className={classes.drawerItem4} selected={this.state.selected == "超热点数据提取"}>
                                  <ListItemText primary="超热点数据提取"/>
                              </ListItem>
                              <ListItem button onClick={this.handleClick("容灾备份")} className={classes.drawerItem4} selected={this.state.selected == "容灾备份"}>
                                  <ListItemText primary="容灾备份"/>
                              </ListItem>
                          </List>
                      </Collapse>
                      <List component="div" disablePadding onClick={this.handleDrawerClose}>
                          <ListItem button onClick={this.handleClick("与云CDN协同")} className={classes.drawerItem3} selected={this.state.selected == "与云CDN协同"}>
                              <ListItemText primary="与云CDN协同"/>
                          </ListItem>
                      </List>
                  </Collapse>
              </List>
          </Collapse>
          <ListItem className="listItem" button onClick={this.handleCollapseChange("title_5")} className={classes.drawerItem}>
              <ListItemText className="listItem" primary="GFLB"/>
              {this.state.collapse["title_5"] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.collapse["title_5"]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding onClick={this.handleDrawerClose}>
                  <ListItem button onClick={this.handleClick("DWRA动态加权轮询")} className={classes.drawerItem2} selected={this.state.selected == "DWRA动态加权轮询"}>
                      <ListItemText primary="DWRA动态加权轮询"/>
                  </ListItem>
                  <ListItem button onClick={this.handleClick("最短链路")} className={classes.drawerItem2} selected={this.state.selected == "最短链路"}>
                      <ListItemText primary="最短链路"/>
                  </ListItem>
              </List>
          </Collapse>

          {/*<ListItem button className={classes.drawerItem}>
            <ListItemText primary="访问日志"/>
        </ListItem>*/}
      </div>);



      const temporaryDrawerProps = {
          variant: "temporary",
          anchor: "left",
          classes: {
              paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          },
          open: this.state.open,
          onClose: this.handleDrawerClose
      };
      const permanentDrawerProps = {
          variant: "permanent",
          className: classes.drawer,
          anchor: "left",
          classes: {
              paper: classes.drawerPaper,
          },
          open: true
      };
    return (
        <div className={classes.root}>
            {/*<Layout>
                <Sider theme="light">
                    <CDNSider handleClick={this.handleClick}/>
                </Sider>
                <Content>
                    <CDNContent data={this.state.data} />
                </Content>
            </Layout>*/}
            {/*<nav className={classes.drawer}>

            </nav>*/}
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar} >
                <Toolbar className={classes.toolBarColor}>
                    <span style={{float:"left"}}>
                        <Link to="/">
                        <img src="/logo.png" alt="Logo" />
                    </Link>
                    </span>

                    <Typography variant="h5" style={{flexGrow:1}} align="center">
                        Fog CDN
                    </Typography>
                </Toolbar>
            </AppBar>
            <Hidden smUp>
                <Drawer
                    {...temporaryDrawerProps}
                >
                    <div className={classes.toolbarIcon}>
                        {/*<IconButton onClick={this.handleDrawerClose}>
                                <ChevronLeftIcon />
                            </IconButton>*/}
                    </div>
                    <Divider />
                    <List>{drawerList}</List>
                </Drawer>
            </Hidden>
            <Hidden xsDown>
                <Drawer
                    {...permanentDrawerProps}
                >
                    <div className={classes.toolbarDrawer} />
                    <Divider />

                    <List>{drawerList}</List>
                </Drawer>
            </Hidden>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {/*<div className={classes.toolbarDrawer} />*/}
                <CDNContent data={this.state.data} />
            </main>
        </div>

    )
  }
}

FogCDN.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FogCDN);