import React, {Component} from 'react'
import Slider from 'react-slick'

const allNews = [
  {
    img: '/assets/news/news11.png',
    title: '梨享荣登深圳特区报头版',
    desc: '5月30日，梨享（Pear）出现在《深圳特区报》头版。《深圳特区报》作为特区最有影响力的党报对梨享（Pear）进行了专题图文报道。',
    href: 'https://km.webrtc.win/index.php?share/file&user=102&sid=DnPteyHs',
  },
  {
    img: '/assets/news/news10.jpg',
    title: '梨享创始人Alan出席香港特首午餐会',
    desc: '5月24日，香港特别行政区行政长官林郑月娥，在香港礼宾府举行午餐会，邀请香港及内地科创企业领袖、高校及科研机构精英共进午餐，座谈交流。梨享（Pear）创始人庄奇东（Alan）应邀出席。',
    href: 'https://km.webrtc.win/index.php?share/file&user=102&sid=JGDdnKmj',
  },
    {
        img: '/assets/news/news9.jpg',
        title: '2019新苗榜“年度科创企业” 揭晓，梨享成行业唯一上榜企业',
        desc: '5月17日，由证券时报主办的第七届“中国创业投资高峰论坛”在深圳正式开幕，梨享（Pear）受邀出席该论坛，并荣登2019中国创业企业新苗榜“年度科创企业”榜单，成为雾计算领域唯一上榜企业。',
        href: 'https://km.webrtc.win/index.php?share/file&user=102&sid=NGtaup82',
    },
    {
        img: '/assets/news/news8.jpg',
        title: 'Pear梨享荣登CCTV-1新闻联播头版头条',
        desc: '2月18日，中共中央、国务院印发了《粤港澳大湾区发展规划纲要》，当日CCTV-1新闻联播于头版头条报道了全力建设粤港澳大湾区的重大国家战略。深圳梨享计算有限公司（Pear）作为粤港澳大湾区示范企业，荣登当日CCTV-1新闻联播头版头条。',
        href: 'https://km.webrtc.win/index.php?share/file&user=102&sid=NDsQarZk',
    },
    {
        img: '/assets/news/news7.jpg',
        title: 'Pear挺进2018年新芽榜中国最具投资价值企业50强',
        desc: '9月20日，2018 Venture50新芽榜50强重磅揭晓。深圳梨享计算有限公司（Pear）历经激烈鏖战、突破层层评选，从众多企业中脱颖而出，位列2018中国创业武林大会|Venture50 新芽榜50强第三名。',
        href: 'https://km.webrtc.win/index.php?share/file&user=102&sid=dGjeNXIp',
    },
    {
        img: '/assets/news/news6.jpg',
        title: '梨享Pear创始人Alan入选《财富》“中国40位40岁以下的商界精英”榜单',
        desc: '2018年9月12日,《财富》杂志中文版公布“2018年中国40位40岁以下的商界精英”榜，梨享Pear创始人庄奇东（Alan）位列其中。',
        href: 'https://km.webrtc.win/index.php?share/file&user=102&sid=jTx5EmRS',
    },
    {
        img: '/assets/news/国务院_港澳办领导视察图片.PNG',
        title: '深圳市南山区区委书记王强陪同国务院相关领导访问Pear',
        desc: '2018年08月12日，深圳市南山区区委书记兼区长王强，陪同国务院港澳办、外事办、工信部的相关领导到访了梨享，并参加了梨享内部的每周例行分享会：Pear Sharing。Pear的U-2计划首席工程师兼设计师Air Chen对该计划作了生动详实的讲解。王强书记等人详细了解了Pear在业务拓展上面临的具体困难，并给出了多条有启发性的建议。',
        href: 'https://km.webrtc.win/index.php?share/file&user=102&sid=dCA3GNbs',
    },
    {
        img: '/assets/news/吴磊发声LiveVideoStack.jpg',
        title: 'Pear高级软件工程师发声LiveVideoStack',
        desc: '2018年7月14日，LiveVideoStack邀请到Pear高级软件工程师吴磊参加其于当日举办的音视频技术会议。会上，吴磊深度剖析了目前网络设备剧增、P2P连接率低、私有协议众多但不能互通等现状，详细讲解了由Pear自主设计研发、支持WebRTC标准的可连接万物的组件和协议：FogConnect/PTP。',
        href: 'https://km.webrtc.win/index.php?share/file&user=102&sid=CNu3dYDc',
    },
    {
        img: '/assets/news/news1.jpeg',
        title: '香港政务司司长参观视察Pear梨享计算发展进程',
        desc: '2017年11月28日，香港政务司司长张建宗来访我司，受到我司工作人员的热烈欢迎。在认真听取了雾计算的工作内容和进展后，他对梨享的业务理念和技术都表示出高度的赞扬，并对梨享目前的工作进展表示肯定。',
        href: 'https://km.webrtc.win/index.php?share/file&user=102&sid=ZgKvprdu',
    },
    {
        img: '/assets/news/news2.jpg',
        title: '香港特首和深圳市委书记到访Pear梨享计算指导工作',
        desc: '2017年8月31日，香港特别行政区行政长官林郑月娥和广东省委常委、深圳市委书记王伟中到访南山智园深港创业基地，并与Pear梨享计算董事长庄奇东进行了亲切交谈。详细了解我司的业务后，特首表示了对我司创新性雾计算业务的肯定。她希望梨享能继续做好雾计算的技术支持与配套服务，为大陆及港澳台等行业客户提供更好的服务体验。此外，林郑特首和伟中书记一致同意，在政府引导基金、政策制定上，给予深港两地科技创新企业以协同支持。',
        href: 'https://km.webrtc.win/index.php?share/file&user=102&sid=B6s7JdyY',
    },
    {
        img: '/assets/news/with_Tony.jpg',
        title: '腾讯联合创始人Tony关注Pear梨享发展',
        desc: '2015年1月24日，腾讯联合创始人、前CTO Tony在南极圈年会上，和Pear梨享计算创始人、时任香港科技大学多媒体技术中心(MTrec)首席架构师Alan面谈，对共享雾计算领域的创业想法进行了高屋建瓴的指导。',
        href: 'https://km.webrtc.win/index.php?share/file&user=102&sid=MUfx9Hhj',
    },
    {
        img: '/assets/news/news3.jpg',
        title: '让雾计算落地，百万边缘节点的背后是提高效率和成本控制',
        desc: '雾计算恰恰可以把闲置的、不稳定的、性能不好的资源变成加速节点，并使得骨干网难以承受的高宽带、低延迟要求的应用（在线VR视频、边缘大数据处理、前端人工智能等）变得可行。从而创造更多的流量、存储、计算资源消耗，使芯片、硬件、ISP、CDN、媒体、应用等多个市场变得更大。',
        href: 'http://36kr.com/p/5118296.html',
    },
    // {
    //   img: '/assets/news/news4.png',
    //   title: '谛听科技正式进军区块链领域 战略投资梨享计算',
    //   desc: '独家消息，成都谛听科技股份有限公司日前完成对深圳梨享计算有限公司（以下简称：Pear）的战略投资，双方将在区块链产业开展深度合作，同时为谛听科技的多元化投资战略打下坚实的基础。据谛听科技官方表示：“Pear这个业内新秀获联想首轮领投，我们也非常看中对方技术能力和业务发展能力，此次部署战略投资也是我们公司战略规划的重要布局。”',
    //   href: 'https://www.jinse.com/blockchain/99767.html',
    // },
    {
        img: '/assets/news/news_Alan.jpeg',
        title: 'Alan Zhuang: How Pear emerges from Fog?',
        desc: 'Billions of people worldwide today are doing computing in the cloud, but the cloud is getting overloaded! And exponential growth in the demand for bandwidth resources is on course to cause major bottlenecks worldwide without a change in technology. This is where far-seeing Alan Zhuang, founder and CEO of Pear Limited, comes in. ',
        href: 'http://alum.ust.hk/alumni-kaleidoscope/1430/alan_zhuang',
    },
    {
        img: '/assets/news/news_hangzhou.jpg',
        title: '杭州市上城区区委常委来访Pear梨享',
        desc: '2018年5月17日，杭州市上城区区委常委常务主任宣寅携委员会领导班子参观了梨享，受到梨享工作人员的热情接待。梨享CEO庄奇东和产品副总裁江英豪为远道而来的客人们详细讲述了公司的雾计算业务。宣主任等人对梨享的创新技术产品和服务表示出浓厚的兴趣，十分看好梨享的发展前景，并邀请梨享参加第四届全球私募基金西湖峰会。',
        href: 'https://km.webrtc.win/index.php?share/file&user=102&sid=iX5AcQS4',
    },

    {
        img: '/assets/news/Alan_Forbes .png',
        title: 'HKUST alumnus Alan Chong has been listed as one of the elites on the Forbes China 30 Under 30 List',
        desc: "Congratulations to Alan Chong, founder of the startup 'Pear Limited', for being selected as one of the elites on the Forbes China 30 Under 30 List in the Enterprise Technology.",
        href: 'https://www.ec.ust.hk/news/hkust-alumnus-alan-chong-has-been-listed-one-elites-forbes-china-30-under-30-list',
    }
]

class News extends Component {
    render() {
        const setting = {
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 3000,
            slidesToShow: 4,
            slidesToScroll: 4
        }
        return (
            <div style={{maxWidth: 1200, margin: '0 auto'}} id="news">
                <Slider {...setting}>
                    {
                        allNews.map((item, index) => (
                            <div className="news-container" key={index}>
                                <div className="news-body">
                                    <div className="news-item">
                                        <div className="news-item-top">
                                            <a href={item.href} target="_blank">
                                                <div>
                                                    <img src={item.img} style={{width: '100%'}} alt={item.title}/>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="news-item-word">
                                            <h2><a href={item.href} target="_blank">{item.title}</a></h2>
                                            <p>{item.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Slider>
            </div>
        )
    }
}

export default News
