import React, { Component } from "react"
import Slider from 'react-slick'

class Carousel extends Component {
  render() {
    const setting = {
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 500,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
    return (
      <div style={{width: '100%', height: '500px', backgroundColor: '#444'}}>
          <div className="img-container">
              {/*<div className="img-word">
            <p className="title">Fog CDN</p>
            <p className="subtitle">节约90%带宽，节省50%CDN成本</p>
          </div>*/}
          </div>
        {/*<Slider {...setting}>
          <div className="img-container">
        </div>
        </Slider>*/}
      </div>
    )
  }
}

export default Carousel
