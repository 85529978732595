import React, { Component } from 'react'
import Top from '../common/top'
import Footer from '../common/footer'
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
// import Partner from '../components/partner'

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1000 + theme.spacing(6))]: {
      width: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  paragraph: {
    lineHeight: '2rem',
    marginBottom: theme.spacing(6),
  },
  title: {
    marginBottom: theme.spacing(5),
  },
  bar: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  }
});

class AboutPage extends Component {
  render() {
    const {classes} = this.props;
    return (
      <div>
        {/*<Top />*/}
        <div className="img-container-about"></div>
        <main className={classes.main}>
          <Typography align="center" variant="h5" gutterBottom className={classes.title}>
            梨享简介
          </Typography>
          <Typography paragraph variant="body1" className={classes.paragraph}>
            诞生于海雾弥漫的香港科技大学校园，梨享（Pear）专注于面向未来的雾计算技术，使数十亿智慧设备能便捷地分享闲置的计算、存储、带宽等资源。
            遵从XaaS（X-即服务）的哲学,以共享经济凝聚，Pear让雾计算技术的一个子集落地。梨享雾计算不仅是跨越网络中心到边缘的资源池，也是新型的点对点传输系统，提供PaaS平台与SaaS服务。
            梨享雾计算在帮企业降低内容分发成本、提高质量的同时帮终端用户通过分享获取收益。
          </Typography>
          <div className={classes.bar}>
            <Typography align="center" variant="h5" gutterBottom>
              梨享使命
            </Typography>
            <Typography align="center" variant="subtitle1" gutterBottom>
              从云到雾，构建多方共赢的共享生态
            </Typography>
          </div>
          <div className={classes.bar}>
            <Typography align="center" variant="h5" gutterBottom>
              梨享愿景
            </Typography>
            <Typography align="center" variant="subtitle1" gutterBottom>
              最受尊敬的雾计算企业
            </Typography>
          </div>

        </main>
        {/*<Footer />*/}
      </div>
    )
  }
}

export default withStyles(styles)(AboutPage)
