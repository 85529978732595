import React, { Component } from 'react'
import Top from '../common/top'
import Footer from '../common/footer'
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
// import Partner from '../components/partner'

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1000 + theme.spacing(6))]: {
      width: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    
  },
  paragraph: {
    lineHeight: '2rem',
    marginBottom: theme.spacing(6),
  },
  title: {
    marginBottom: theme.spacing(5),
  },
  bar: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  // root: {
  //   marginTop: theme.spacing.unit * 3,
  //   overflowX: 'auto',
  //   ...theme.mixins.gutters(),
  //   paddingTop: theme.spacing.unit * 2,
  //   paddingBottom: theme.spacing.unit * 2,
  // },
  table: {
    minWidth: 700,
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  pimage: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  pimage_gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  pimage_gridListTile: {
    transform: 'translateZ(0) scale(0.7)',
  },
  pimage_title: {
    color: theme.palette.primary.light,
  },
  pimage_titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
});

class PearStation extends Component {
  render() {
    const {classes} = this.props;
    return (
      <div>
        {/*<Top />*/}
        
        <main className={classes.main}>
          <ProductionImage {...this.props}/>
          <ProductionBrief  {...this.props} />
          <ProductionDetailTable {...this.props}/>
        </main>
        {/*<Footer />*/}
      </div>
    )
  }
}

const tileData = [
  {
    src: "https://download.openfogos.com/static/pear.hk/images/goods/pear-station/p1.png",
    title: '渲染图',
  },
  {
    src: "https://download.openfogos.com/static/pear.hk/images/goods/pear-station/p2.jpg",
    title: '实拍图机箱外壳',
  },
  {
    src: "https://download.openfogos.com/static/pear.hk/images/goods/pear-station/p3.jpg",
    title: '电源',
  },
  {
    src: "https://download.openfogos.com/static/pear.hk/images/goods/pear-station/p4.png",
    title: '背板',
  },
];

function ProductionImage (props) {
  const {classes} = props;
  return <>
    <Paper className={classes.paper} elevation={1}>
      <video controls >
        <source src="https://download.openfogos.com/static/pear.hk/images/goods/pear-station/v1.mp4" alt={"tt"} type="video/mp4" />
        <a href="/media/cc0-videos/flower.mp4">MP4</a>
      </video>
      <div className={classes.pimage}>
        <GridList className={classes.pimage_gridList}>
          {tileData.map(tile => (
            <GridListTile key={tile.img} cols={1} rows={3} className={classes.pimage_gridListTile}>
              <img src={tile.src} alt={tile.title} className={classes.pimage_img}/>
            </GridListTile>
          ))}
        </GridList>
      </div>
    </Paper>
  </>
}



function ProductionBrief(props){
  const {classes} = props;
  return <>
    <Paper className={classes.paper} elevation={1}>
        <Typography variant="h6" gutterBottom="true">
          产品概述
        </Typography>
        <Typography component="p">
          AMD 64 线程 + 128G内存 + NVIDIA RTX 3080TI 12G 显卡
        </Typography>
        <br/>
        <Typography variant="h6" gutterBottom="true">
          海量存储
        </Typography>
        <Typography component="p" gutterBottom="true">
          128G 超大内存
        </Typography>
        <Typography component="p" gutterBottom="true">
          整机经过严格的兼容性和可靠性测试，稳定性能全面提升。
        </Typography>
        <Typography component="p" gutterBottom="true">
          1000W 电源。
        </Typography>
        
      </Paper>
  </>
}

function ProductionDetailTable (props) {
  const {classes} = props;
  return (
    <Paper className={classes.paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align='right'>
              名称
            </TableCell>
            <TableCell>产品规格</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" align='right'>
                系统
              </TableCell>
              <TableCell>
                Windows 10 Workstation ubuntu Linux 22.04 LTS或PVE
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" align='right'>
                风扇
              </TableCell>
              <TableCell>
                静音风扇
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" align='right'>
                内存
              </TableCell>
              <TableCell>
                128GB DDR4
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" align='right'>
                硬盘
              </TableCell>
              <TableCell>
                1.92T工业级SSD系统盘数据盘二合一
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" align='right'>
                CPU
              </TableCell>
              <TableCell>
                AMD 64线程CPU
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" align='right'>
                机箱
              </TableCell>
              <TableCell>
                机箱
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" align='right'>
                电源
              </TableCell>
              <TableCell>
                1000W
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" align='right'>
                显卡
              </TableCell>
              <TableCell>
                NVIDIA RTX 3080 TI 12G 显卡
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" align='right'>
                其他
              </TableCell>
              <TableCell>
                系统静音风扇
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" align='right'>
                合计
              </TableCell>
              <TableCell>
                
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" align='right'>
                含税
              </TableCell>
              <TableCell>
                33000
              </TableCell>
            </TableRow>
        </TableBody>
      </Table>
  </Paper>
  )
}

export default withStyles(styles)(PearStation)
