import React, { Component } from 'react'
import Top from '../common/top'
import Footer from '../common/footer'
import Carousel from '../components/carousel'
import Pandect from '../components/pandect'
import News from '../components/news'
import Desc from '../components/desc'
import Concat from '../components/concat'
import Tool from '../common/tooltip'
// import Partner from '../components/partner'

class Homepage extends Component {
  render() {
    return (
      <div>
        {/*<Top />*/}
        <Carousel />
        <Pandect />
        <Desc />
        <News />
        {/* <Partner /> */}
        {/*<Concat />*/}
        {/*<Footer />*/}
        <Tool />
      </div>
    )
  }
}

export default Homepage
