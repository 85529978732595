import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import Top from '../common/top';
import Footer from '../common/footer';
import {HomeRoutes} from "../routes";



const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    content: {
        margin: theme.spacing(2),
    },
    main: {
        minWidth: 275,
        maxWidth: 1000,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    footer: {
        marginTop: theme.spacing(5)
    },
});

const HomeLayout = (props) => {
    const { classes } = props;
    return (
        <div className={classes.root}>
            <Hidden smUp>
                <Top/>
            </Hidden>
            <Hidden xsDown>
                <Top/>
            </Hidden>


             <HomeRoutes/>
            <Footer/>



        </div>
    );
}

HomeLayout.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeLayout);
/*
import React from 'react'
import { Layout } from 'antd'
import { Header, Footer } from '../common/'
import { HomeRoutes } from '../Route'

const { HomeHeader } = Header
const { Content } = Layout

const HomeLayout = (props) => {
  return (
    <Layout>
      <HomeHeader />
      <Layout className="ant-layout-has-sider">
        {/!*<Sider {...props} />*!/}
        <Layout>
          <Content style={{padding: 20}}>
            <HomeRoutes />
            <Footer />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default HomeLayout
*/
