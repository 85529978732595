import React from 'react'

class Tool extends React.Component {
  mouseOver = () => {
    const hide = document.getElementById('hide')
    hide.style.visibility = 'visible'
    hide.style.opacity = 1
  }
  mouseOut = () => {
    const hide = document.getElementById('hide')
    setTimeout(() => {
      hide.style.visibility = 'hidden'
      hide.style.opacity = 0
    }, 300);
  }
  render() {
    return (
      <div className="toolbox">
      {/* <div className="toolbox" onMouseOver={this.mouseOver} onMouseOut={this.mouseOut}> */}
        <div id="hide" className="tool-hide">
          <div className="tool-word">service@pear.hk</div>
        </div>
      </div>
    )
  }
}

export default Tool
