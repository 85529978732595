import React, { Component } from 'react'
import './index.css'

class HelpContent extends Component {
  getCode = (data) => {
    let string = ''
    data.params.forEach((item, index) => {
      if(index === data.params.length - 1) {
        string += `${item.name}=xxx`
      } else {
        string += `${item.name}=xxx&\n`
      }
    })
    return string
  }
  render() {
    const data = this.props.data
    return (
      <div className="helpContent-container">
        <h2>{`${data.desc}：${data.name}`}</h2>
        <h3>方法：{data.method}</h3>
        {
          data.method === 'get' 
          ?
            <div>
              {
                data.params
                ?
                <div key={data.name}>
                  <h3>其中，URL 的结构为 ：</h3>
                  <div className="code-container">
                    <pre>
                      <code>
                        <span>{data.name + '?'}</span>
                        {this.getCode(data)}
                      </code>
                    </pre>
                  </div>
                  <h3>URL 中各字段含义如下：</h3>
                  <table>
                    <tbody>
                    <tr>
                      <th>参数名称</th>
                      <th>必选</th>
                      <th>类型</th>
                      <th>描述</th>
                    </tr>
                    {
                      data.params.map(data => {
                        return (
                        <tr key={data.name}>                        
                          {Object.keys(data).map(i => {
                            if(data[i] === true) {
                              return <td key={i}>是</td>
                            } else if(data[i] === false) {
                              return <td key={i}>否</td>
                            } else {
                              return <td key={i}>{data[i]}</td>
                            }
                          }
                          )}
                        </tr> 
                        )
                      })
                    }
                    </tbody>
                  </table>
                </div> : null
              }
              {data.response ?
                  <div>
                  <h3>返回结构</h3>
                  <table>
                    <tbody>
                    <tr>
                      <th>参数名称</th>
                      <th>类型</th>
                      <th>说明</th>
                    </tr>
                    {
                      data.response.map((data, index) => {
                        return (
                        <tr key={index}>
                          {Object.keys(data).map(i => (
                            <td key={i}>{data[i]}</td>
                          ))}
                        </tr> 
                        )
                      })
                    }
                    </tbody>
                  </table>
                  <h3>返回消息示例如下：</h3>
                  <div className="code-container">
                    <pre>
                      <code>
                        {data.body}
                      </code>
                    </pre>
                  </div>
                </div> : null}
            </div>
          :
          <div>
            {data.response ?
                  <div>
                  <h3>请求结构</h3>
                  <table>
                    <tbody>
                    <tr>
                      <th>参数名称</th>
                      <th>类型</th>
                      <th>说明</th>
                    </tr>
                    {
                      data.response.map((data, index) => {
                        return (
                        <tr key={index}>
                          {Object.keys(data).map(i => (
                            <td key={i}>{data[i]}</td>
                          ))}
                        </tr> 
                        )
                      })
                    }
                    </tbody>
                  </table>
                </div> : null}
            <h3>请求Body示例为 ：</h3>
            <div className="code-container">
              <pre>
                <code>
                  {data.body}
                </code>
              </pre>
            </div>
          </div>
        }
      </div>
      // <div className="helpContent-container">
      //   {
      //     data.method === 'get' 
      //     ?
      //       data.params
      //       ?
      //       <div key={data.name}>
      //         <h2>{`${data.desc}：${data.name}`}</h2>
      //         <h3>其中，URL 的结构为 ：</h3>
      //         <div className="code-container">
      //           <pre>
      //             <code>
      //               <span>{data.name + '?'}</span>
      //               {this.getCode(data)}
      //             </code>
      //           </pre>
      //         </div>
      //         <h3>URL 中各字段含义如下：</h3>
      //         <table>
      //           <tbody>
      //           <tr>
      //             <th>参数名称</th>
      //             <th>必选</th>
      //             <th>类型</th>
      //             <th>描述</th>
      //           </tr>
      //           {
      //             data.params.map(data => {
      //               return (
      //               <tr key={data.name}>                        
      //                 {Object.keys(data).map(i => {
      //                   if(data[i] === true) {
      //                     return <td key={i}>是</td>
      //                   } else if(data[i] === false) {
      //                     return <td key={i}>否</td>
      //                   } else {
      //                     return <td key={i}>{data[i]}</td>
      //                   }
      //                 }
      //                 )}
      //               </tr> 
      //               )
      //             })
      //           }
      //           </tbody>
      //         </table>
      //         {data.response ?
      //         <div>
      //         <h3>返回结构</h3>
      //         <table>
      //           <tbody>
      //           <tr>
      //             <th>参数名称</th>
      //             <th>类型</th>
      //             <th>说明</th>
      //           </tr>
      //           {
      //             data.response.map((data, index) => {
      //               return (
      //               <tr key={index}>
      //                 {Object.keys(data).map(i => (
      //                   <td key={i}>{data[i]}</td>
      //                 ))}
      //               </tr> 
      //               )
      //             })
      //           }
      //           </tbody>
      //         </table>
      //         <h3>返回消息示例如下：</h3>
      //         <div className="code-container">
      //           <pre>
      //             <code>
      //               {data.body}
      //             </code>
      //           </pre>
      //         </div>
      //       </div> : null}
      //       </div>
      //       :
      //       <div>
      //         <h2>{`${data.desc}：${data.name}`}</h2>
      //         <h3>返回消息示例如下：</h3>
      //         <div className="code-container">
      //           <pre>
      //             <code>
      //               {data.body}
      //             </code>
      //           </pre>
      //         </div>
      //       </div>
      //     :
      //     <div>
      //       <h2>{`${data.desc}：${data.name}`}</h2>
      //       <h3>请求Body为 ：</h3>
      //       <div className="code-container">
      //         <pre>
      //           <code>
      //             {data.body}
      //           </code>
      //         </pre>
      //       </div>
      //     </div>
      //   }
      // </div>
    )
  }
}

export default HelpContent
