import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';

export default createMuiTheme({
    palette: {
        // primary: blue,
        /*primary: {
            light: '#676767',
            main: '#3d3d3d',
            dark: '#171717',
            contrastText: '#fcd68b',

        },*/
        primary: {//官方金
            light: '#dab46d',
            main: '#a68540',
            dark: '#745813',
            contrastText: '#000',
        },
        /*primary: {//漂亮金
            light: '#ffe080',
            main: '#cfae51',
            dark: '#9b7f22',
            contrastText: '#000',
        },*/
        // secondary: {//目前的按钮色
        //     light: '#dab46d',
        //     main: '#cfae51',
        //     dark: '#9a7a15',
        //     contrastText: '#000',
        // },
        secondary: {
            light: '#494949',
            main: '#222',
            dark: '#000',
            contrastText: '#fff',
        },
    },
    typography: {
        useNextVariants: true,
    },
    // overrides: {
    //     MuiCard: { // 组件的名称 ⚛️ / 样式表
    //          // 规则的名称
    //         root: {
    //                 backgroundColor: '#b2b2b2'
    //         }
    //
    //     },
    // },
});